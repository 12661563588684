import axios from 'axios';

import { analyzeFile } from '@/helpers/uploadFileHelper.js';
const REQUEST_TIMEOUT = 180000;
axios.defaults.timeout = REQUEST_TIMEOUT;
/* import router from "../router";
 */
const API_URL = process.env.VUE_APP_API_URL

const mergeByProperty = (target, source, prop) => {
  source.forEach(sourceElement => {
    let targetElement = target.find(targetElement => {
      return sourceElement[prop] === targetElement[prop];
    })
    targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
  })
}
const makeid = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const tzOffsetToSeconds = (tzOffset) => {
  if (!tzOffset) return 0;
  const matchedOffset = /(-*\d+):(\d+)/g.exec(tzOffset);
  let output = 0;
  if (matchedOffset && matchedOffset[1]) {
    const hours = Number(matchedOffset[1] || 0);
    const minutes = Number(matchedOffset[2] || 0);
    output = (hours * 60 * 60) + (minutes * 60);
  }
  return output;
}


const currentTimestamp = () => {
  return Math.round(Date.now() / 1000);
}

const currentDateStamp = (endOfDay) => {
  const now = new Date();
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  if (endOfDay === true) startOfDay.setHours(23, 59, 59, 0);
  return startOfDay.getTime() / 1000;
}

const sharedReporting = {};
const savedReporting = {};

const defaultCampaign = {
  creatives: [],
  data: {
    impressions: 0,
    engagement: 0,
    clicks: 0
  }
}
const defaultCreative = {
  assets: [],
  type: 'Banner',
  name: 'New creative',
  data: {
    impressions: 0,
    engagement: 0,
    clicks: 0
  },
  trackers: {}
}

const platformMacros = {
  "Google Ad Manager": {
    clickMacro: "%%CLICK_URL_UNESC%%"
  },
  "Google Campaign Manager": {
    clickMacro: "%c"
  },
  "Google DV360": {
    clickMacro: "${CLICK_URL}"
  },
  "Quantcast": {
    clickMacro: "${CLICK}"
  },
  "Cognitiv": {
    clickMacro: "",
    thirdparty: {
      "Auction-ID": "{{AUCTION_ID}}",
      "User-ID": "{{COGNITIV_PERSON_IID}}",
      "Page-URL": "{{PAGE_URL}}"
    }
  },
  "The Trade Desk": {
    clickMacro: "%%TTD_CLK%%"
  },
  "MediaMath": {
    clickMacro: "[UNENCODED_CLICK_REDIRECT]"
  },
  "AdRoll": {
    clickMacro: "[adrclickurl_unesc]"
  },
  "Xandr": {
    clickMacro: "${CLICK_URL}",
    additional: {
      "platform": "xandr",
      "in-preview": "${IS_PREVIEW}",
      "supply-type": "${SUPPLY_TYPE}"
    }
  }
}

const CampaignsService = {
  getByID: (store) => (id) => {
    const state = store.campaigns;
    let output = {
      id: id,
      found: false,
      creatives: []
    };
    state.forEach((campaign) => {
      if (campaign.id === id) {
        output = campaign;
        output.found = true;
      }
    });
    return output;
  },
  getCreativeByID: (store) => (id) => {
    const state = store.campaigns;
    let output = null;
    state.forEach((campaign) => {
      if (!campaign.creatives) return;
      campaign.creatives.forEach((creative) => {
        if (creative.id === id) {
          output = creative;
          output.found = true;
        }
      });
    });
    return output;
  },

  getCampaign: (store, state) => (id) => {
    const campaigns = store.campaigns;
    return new Promise((resolve) => {
      axios
        .get(API_URL + '/v1/campaign/get?campaignID=' + id, { withCredentials: true })
        .then(response => {
          if (response.status >= 200 && response.data.status < 400) {
            const campaign = response.data.data;
            let found = false;
            campaigns.forEach((camp) => {
              if (camp.id === campaign.id) {
                Object.assign(camp, campaign);
                camp.dataPulledAt = currentTimestamp();
                if (!camp.creatives || !(camp.creatives instanceof Array)) camp.creatives = [];
                found = true;
              }
            })
            if (!found) {
              campaigns.push(campaign);
            }
            state.saveState();
            resolve(campaign);
          }
        });
    })
  },

  getCampaignDateReport: (store, state) => (id, start_dt, end_dt, tzOffset = '+00:00', type = 'json') => {
    return new Promise((resolve, reject) => {
      const camp = state.getByID(id);
      console.log("getCampaignDateReport", start_dt, end_dt)
      if (!start_dt) {
        start_dt = currentDateStamp() - 60 * 60 * 24 * 7;
      }
      if (!end_dt) {
        end_dt = currentDateStamp(true);
      }

      const startDateTime = start_dt instanceof Date ? start_dt : new Date(start_dt * 1000);
      const endDateTime = end_dt instanceof Date ? end_dt : new Date(end_dt * 1000);

      if (tzOffset && tzOffset != '+00:00') {
        const seconds = tzOffsetToSeconds(tzOffset);
        startDateTime.setTime(startDateTime.getTime() + (seconds * 1000));
        endDateTime.setTime(endDateTime.getTime() - (seconds * 1000));
      }

      if (savedReporting[camp.id] && savedReporting[camp.id].lastReportUpdate && type == 'json') {
        if (camp.lastReportDates && camp.lastReportDates.start === start_dt && camp.lastReportDates.end === end_dt && (currentTimestamp() - camp.lastReportUpdate < 3600)) {
          return resolve(savedReporting[camp.id]);
        }
      }

      axios
        .get(API_URL + `/v1/campaign/reports`, {
          withCredentials: true,
          timeout: REQUEST_TIMEOUT,
          params: {
            campaignID: id,
            start_dt: startDateTime.toISOString(),
            end_dt: endDateTime.toISOString(),
            tzOffset: tzOffset,
            type: type,
            granularity: 'full'
          }
        }
        )
        .then(response => {
          if (response.status >= 200 && response.data.status < 400) {
            if (type == 'json') {
              const report = response.data.data;
              savedReporting[camp.id] = report;

              if (report.reportToken && report.reportToken[0]) {
                camp.reportURL = report.reportToken[0].url;
              }
              if (report.scheduledReports) {
                if (!camp.scheduledReports) camp.scheduledReports = [];
                camp.scheduledReports.push(...report.scheduledReports);
              }

              camp.lastReportUpdate = currentTimestamp();
              camp.lastReportDates = {
                start: start_dt,
                end: end_dt
              };

              state.saveState();
              resolve(report);
            } else {
              const report = response.data;
              resolve(report);

            }
          }
        })
        .catch((error) => {
          if (error.code === "ERR_CANCELED") {
            reject("Request timed out");
          } else {
            reject(error.message);
          }
        });
    })
  },

  getSharedDateReport: (store, state) => (id, start_dt, end_dt, tzOffset = '+00:00', type = 'json') => {
    return new Promise((resolve, reject) => {
      if (!start_dt) {
        start_dt = currentDateStamp() - 60 * 60 * 24 * 7;
      }
      if (!end_dt) {
        end_dt = currentDateStamp(true);
      }

      const startDateTime = start_dt instanceof Date ? start_dt : new Date(start_dt * 1000);
      const endDateTime = end_dt instanceof Date ? end_dt : new Date(end_dt * 1000);

      if (tzOffset && tzOffset != '+00:00') {
        const seconds = tzOffsetToSeconds(tzOffset);
        startDateTime.setTime(startDateTime.getTime() + (seconds * 1000));
        endDateTime.setTime(endDateTime.getTime() - (seconds * 1000));
      }
      if (sharedReporting[id] && sharedReporting[id].lastReportUpdate && type == 'json') {
        if (sharedReporting[id].lastReportDates && sharedReporting[id].lastReportDates.start === start_dt && sharedReporting[id].lastReportDates.end === end_dt && (currentTimestamp() - sharedReporting[id].lastReportUpdate < 3600)) {
          return resolve(sharedReporting[id]);
        }
      }

      axios
        .get(API_URL + `/share/reports`, {
          withCredentials: true,
          timeout: REQUEST_TIMEOUT,
          params: {
            sharedToken: id,
            start_dt: startDateTime.toISOString(),
            end_dt: endDateTime.toISOString(),
            tzOffset: tzOffset,
            type: type,
            granularity: 'full'
          }
        }
        )
        .then(response => {
          if (response.status >= 200 && response.data.status < 400) {
            if (type == 'json') {
              const resp = response.data;
              if (resp.report) {
                resp.lastReportUpdate = currentTimestamp();
                resp.lastReportDates = {
                  start: start_dt,
                  end: end_dt
                };
              }
              sharedReporting[id] = resp;
              state.saveState();
              resolve(resp);
            } else {
              const report = response.data;
              resolve(report);

            }
          }
        })
        .catch((error) => {
          if (error.code === "ERR_CANCELED") {
            reject("Request timed out");
          } else {
            reject(error.message);
          }
        });
    })
  },

  getCreativesDateReport: (store, state) => (id, liveids, start_dt, end_dt, tzOffset = '+00:00', type = 'json') => {
    return new Promise((resolve, reject) => {
      if (!liveids || liveids.length < 1) reject('No live ids set');
      const camp = state.getByID(id);

      if (!start_dt) {
        start_dt = currentDateStamp() - 60 * 60 * 24 * 7;
      }
      if (!end_dt) {
        end_dt = currentDateStamp(true);
      }

      const startDateTime = start_dt instanceof Date ? start_dt : new Date(start_dt * 1000);
      const endDateTime = end_dt instanceof Date ? end_dt : new Date(end_dt * 1000);

      if (tzOffset && tzOffset != '+00:00') {
        const seconds = tzOffsetToSeconds(tzOffset);
        startDateTime.setTime(startDateTime.getTime() + (seconds * 1000));
        endDateTime.setTime(endDateTime.getTime() + (seconds * 1000));
      }

      axios
        .get(API_URL + `/v1/campaign/reports`, {
          withCredentials: true,
          timeout: REQUEST_TIMEOUT,
          params: {
            liveIDs: liveids.join(','),
            start_dt: startDateTime.toISOString(),
            end_dt: endDateTime.toISOString(),
            tzOffset: tzOffset,
            type: type,
            granularity: 'full'
          }
        }
        )
        .then(response => {
          if (response.status >= 200 && response.data.status < 400) {
            const report = response.data.data;
            camp.lastReportDates = {
              start: start_dt,
              end: end_dt
            };

            state.saveState();
            resolve(report);
          }
        })
        .catch((error) => {
          if (error.code === "ERR_CANCELED") {
            reject("Request timed out");
          } else {
            reject(error.message);
          }
        });
    })
  },

  getCreativesDateEvents: (store, state) => (id, liveids, start_dt, end_dt, tzOffset = '+00:00', type = 'json') => {
    return new Promise((resolve, reject) => {
      if (!liveids || liveids.length < 1) reject('No live ids set');
      const camp = state.getByID(id);

      if (!start_dt) {
        start_dt = currentDateStamp() - 60 * 60 * 24 * 7;
      }
      if (!end_dt) {
        end_dt = currentDateStamp(true);
      }

      const startDateTime = start_dt instanceof Date ? start_dt : new Date(start_dt * 1000);
      const endDateTime = end_dt instanceof Date ? end_dt : new Date(end_dt * 1000);

      if (tzOffset && tzOffset != '+00:00') {
        const seconds = tzOffsetToSeconds(tzOffset);
        startDateTime.setTime(startDateTime.getTime() + (seconds * 1000));
        endDateTime.setTime(endDateTime.getTime() + (seconds * 1000));
      }

      axios
        .get(API_URL + `/v1/campaign/events`, {
          withCredentials: true,
          timeout: REQUEST_TIMEOUT,
          params: {
            liveIDs: liveids.join(','),
            start_dt: startDateTime.toISOString(),
            end_dt: endDateTime.toISOString(),
            tzOffset: tzOffset,
            type: type,
            granularity: 'full'
          }
        }
        )
        .then(response => {
          if (response.status >= 200 && response.data.status < 400) {
            const report = response.data.data;
            camp.lastEventDates = {
              start: start_dt,
              end: end_dt
            };

            state.saveState();
            resolve(report);
          }
        })
        .catch((error) => {
          if (error.code === "ERR_CANCELED") {
            reject("Request timed out");
          } else {
            reject(error.message);
          }
        });
    })
  },

  getCampaignReport: (store, state) => (id, type = 'json') => {
    return new Promise((resolve, reject) => {
      const camp = state.getByID(id);
      if (camp.report && camp.report.lastUpdated && currentTimestamp() - camp.report.lastUpdated < 3600) {
        const report = camp.report;
        /* if (report.creatives) {
          if (camp.creatives) {
            camp.creatives.forEach((creative) => {
              if (creative.liveCreatives) {
                creative.liveCreatives.forEach((liveCreative) => {
                  if (report.creatives[liveCreative.id]) {
                    liveCreative.reporting = report.creatives[liveCreative.id];
                  }
                })
              }
            })
          }
        } */

        return resolve(report);
      }
      axios
        .get(API_URL + '/v1/campaign/reports?campaignID=' + id + '&type=' + type, { withCredentials: true, timeout: REQUEST_TIMEOUT })
        .then(response => {
          if (response.status >= 200 && response.data.status < 400) {
            const report = response.data.data;
            camp.report = report;
            camp.dataPulledAt = currentTimestamp();

            if (report.reportToken && report.reportToken[0]) {
              camp.reportURL = report.reportToken[0].url;
            }
            if (report.scheduledReports) {
              if (!camp.scheduledReports) camp.scheduledReports = [];
              camp.scheduledReports.push(...report.scheduledReports);
            }
            /* if (report.creatives) {
              if (camp.creatives) {
                camp.creatives.forEach((creative) => {
                  if (creative.liveCreatives) {
                    creative.liveCreatives.forEach((liveCreative) => {
                      if (report.creatives[liveCreative.id]) {
                        liveCreative.reporting = report.creatives[liveCreative.id];
                      }
                    })
                  }
                })
              }
            } */
            state.saveState();
            resolve(report);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          if (error.code === "ERR_CANCELED") {
            reject("Request timed out");
          } else {
            reject(error.message);
          }
        });
    })
  },

  getCreativesReport: (store, state) => (liveids, type = 'json') => {
    return new Promise((resolve, reject) => {
      axios
        .get(API_URL + '/v1/campaign/reports?liveIDs=' + liveids.join(',') + '&type=' + type, { withCredentials: true, timeout: REQUEST_TIMEOUT })
        .then(response => {
          if (response.status >= 200 && response.data.status < 400) {
            const report = response.data.data;
            /* campaigns.forEach((camp) => {
              if (camp.id === id) {
                Object.assign(camp.data,report);
                camp.dataPulledAt = currentTimestamp();
              }
            }) */
            state.saveState();
            resolve(report);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          if (error.code === "ERR_CANCELED") {
            reject("Request timed out");
          } else {
            reject(error.message);
          }
        });
    })
  },

  getAll: (store, ctx) => (nextPage) => {
    const mineOrAll = store.listView !== 'all';
    return new Promise((resolve, reject) => {
      let queryString = '';
      if (nextPage === true && store.listHasMore) {
        queryString = '?paginate[page]=' + (store.listPage + 1);
      }
      axios
        .get(API_URL + '/v1/campaign/' + (mineOrAll ? 'mine' : 'all') + queryString, { withCredentials: true })
        .then(response => {
          if (response.status >= 200 && response.status < 400 || response.status === 403) {
            if (!response.data || !response.data.data) {
              store.campaigns = [];
              return resolve(store.campaigns);
            }
            const campaigns = response.data.data;
            if (campaigns.length > 0) {
              campaigns.forEach((camp) => {
                camp.dataPulledAt = currentTimestamp();
                if (!camp.creatives || !(camp.creatives instanceof Array)) camp.creatives = [];
              })
              store.listHasMore = true;
            }
            store.listPage = response.data.pagination.page;

            if (nextPage) {
              store.campaigns.append(...campaigns);

            } else {
              store.campaigns = campaigns;

            }
            resolve(store.campaigns);
            ctx.saveState();
          } else {
            reject(response.data);
          }
        }, rejected => {
          console.error("Rejected", rejected);
          const response = rejected.response;
          if (!response.data || !response.data.data) {
            store.campaigns = [];
            return resolve(store.campaigns);
          }
          reject(rejected);
        });
    });

    /* state.forEach((campaign) => {
      if (campaign.creatives) {
        campaign.creatives.forEach((creative) => {
          creative.campaignID = campaign.id;
        })
      }
    }); */
  },

  getCreativeTags: () => (creatives, type = 'plain') => {
    const tagOutput = [];
    creatives.forEach((creative) => {
      if (creative.liveCreatives && creative.liveCreatives.length > 0) {
        let tagURL = '';
        const liveID = creative.liveCreatives[0].id;
        const platformSpecific = creative.platform && platformMacros[creative.platform] || {};
        if (creative.type === 'VAST') {
          tagURL = 'https://serve' + (process.env.NODE_ENV === 'development' ? '-test' : '') + '.aderserve.com/v/' + liveID + '/' + liveID + '.xml';
        } else {
          const initURL = 'https://serve' + (process.env.NODE_ENV === 'development' ? '-test' : '') + '.aderserve.com/b/' + liveID + '/init.js';
          let clickURL = '[INSERT CLICK URL]';
          const trackersList = [];
          if (creative.trackers) {
            if (creative.trackers.click) {
              clickURL = creative.trackers.click[0].url;

              clickURL = clickURL.replace('servedby.flashtalking.com','[[FLASHTALKING_DOMAIN]]');
            }
            for (let k in creative.trackers) {
              if (k === 'click') continue;
              const trackers = creative.trackers[k];
              trackers.forEach((tracker, indx) => {
                tracker.url = tracker.url.replace('servedby.flashtalking.com','[[FLASHTALKING_DOMAIN]]');
                trackersList.push('ader-' + tracker.type + '-tracker' + (trackers.length > 1 ? '[' + indx + ']' : '') + '="' + tracker.url + '"');
              })
            }
          }
          if (platformSpecific.thirdparty) {
            trackersList.push('');
            for (let k in platformSpecific.thirdparty) {
              const tracker = platformSpecific.thirdparty[k];
              trackersList.push('ader-tp-' + k + '="' + tracker + '"');
            }
          }
          if (platformSpecific.additional) {
            trackersList.push('');
            for (let k in platformSpecific.additional) {
              const tracker = platformSpecific.additional[k];
              trackersList.push('ader-' + k + '="' + tracker + '"');
            }
          }

          tagURL = '<ins ader-id="' + liveID + '"\n\
          ader-clicks="yes"\n\
          ader-does-redirect="yes"\n' +
            '          ader-click-macro="' + (platformSpecific.clickMacro || '') + '"\n' +
            '          ader-click-through="' + clickURL + '"\n\
          ader-width="'+ creative.width + '"\n\
          ader-height="'+ creative.height + '"\n\
          '+ trackersList.join('\n          ') + '>\n\
            <script src="' + initURL + '" type="text/javascript" async></script>\n\
</ins>'
        }
        tagOutput.push({
          name: creative.name,
          tagURL: tagURL,
          sizing: creative.fluid ? 'Responsive' : creative.width + 'x' + creative.height,
          platform: creative.platform || null,
          type: creative.type,
          id: liveID
        })
      }
    });
    if (type === 'plain') {
      let tagString = '';
      tagOutput.forEach((tag) => {
        tagString += '########## ' + tag.name + ' ##########\n';
        tagString += 'Ad size: ' + tag.sizing + '\n';
        tagString += 'Tag type: ' + tag.type + '\n';
        tagString += tag.platform ? 'Traffic platform: ' + tag.platform + '\n' : '\n';
        tagString += tag.type === 'VAST' ? 'VAST URL: ' + tag.tagURL : tag.tagURL;
        tagString += '\n\n'
      })
      return tagString.trim();
    } else {
      return tagOutput;
    }
  },

  downloadTags: (state,store) => (campaign_id, creatives) => {
    if (!campaign_id || campaign_id === 'new') return;
    //store.loading = true;
    return new Promise((resolve, reject) => {
      const tags = store.getCreativeTags(creatives, 'json');
      const outTags = {};
      tags.forEach((tag) => {
        outTags[tag.id] = tag.tagURL;
      })

      axios
        .post(
          API_URL + '/v1/campaign/tags',
          {
            campaignID: campaign_id,
            type: 'xlsx',
            tags: outTags
          },
          { withCredentials: true }
        )
        .then(response => {
          if (response.status >= 200 && response.data.status < 400) {
            const resp = response.data || [];

            //store.loading = false;
            return resolve(resp);


          }
          return reject(response);
        }).catch((reason) => reject(reason));

    })
  },

  getCreatives: (store, state) => (campaign_id) => {
    if (!campaign_id || campaign_id === 'new') return;
    //store.loading = true;
    return new Promise((resolve, reject) => {
      axios
        .get(
          API_URL + '/v1/campaign/creatives?campaignID=' + campaign_id,
          { withCredentials: true }
        )
        .then(response => {
          if (response.status >= 200 && response.data.status < 400) {
            const creatives = response.data.data || [];
            creatives.forEach((creative) => {
              creative.dataPulledAt = currentTimestamp();
              if (!creative.assets || !(creative.assets instanceof Array)) creative.assets = [];
            })
            //let found = false;
            store.campaigns.forEach((campaign) => {
              if (campaign.id === campaign_id) {
                mergeByProperty(campaign.creatives, creatives, "id");

                state.update(campaign, false);
                //found = true;
              }
            });
            //store.loading = false;
            return resolve(creatives);


          }
          return reject(response);
        }).catch((reason) => reject(reason));

    })
  },

  createNew: (store, state) => (data, onFinish) => {
    if (!data) return false;
    let campaign = Object.assign({}, defaultCampaign, data);
    campaign.updatedAt = currentTimestamp();
    const tempID = 'new_' + makeid(14);
    campaign.id = tempID;
    campaign.new = true;
    campaign.creatives = [];
    axios
      .post(
        API_URL + '/v1/campaign/new',
        campaign,
        { withCredentials: true }
      )
      .then(response => {
        if (response.status === 200) {
          let found = false;
          const responseCampaign = response.data.data;
          if (!responseCampaign.creatives) responseCampaign.creatives = [];
          responseCampaign.dataPulledAt = currentTimestamp();
          store.campaigns.forEach((campaign) => {
            if (campaign.id === tempID || campaign.id === responseCampaign.id) {
              campaign = responseCampaign;
              found = true;
            }
          })
          if (!found) {
            store.campaigns.push(responseCampaign);
          }
          state.saveState();
          if (onFinish) onFinish(responseCampaign);
        }
      });
    return campaign;
  },


  createNewReportToken: () => (data) => {
    return new Promise((resolve, reject) => {
      if (!data) return reject('No data');
      axios
        .post(
          API_URL + '/v1/campaign/newToken',
          data,
          { withCredentials: true }
        )
        .then(response => {
          if (response.status === 200) {
            const responseCampaign = response.data;
            resolve(responseCampaign);
          } else {
            reject(response);
          }
        });
    })

  },


  createNewScheduledReport: (store,state) => (data) => {
    return new Promise((resolve, reject) => {
      if (!data) return reject('No data');
      axios
        .post(
          API_URL + '/v1/campaign/scheduledReport',
          data,
          { withCredentials: true }
        )
        .then(response => {
          if (response.status === 200) {
            const responseCampaign = response.data;
            if (responseCampaign.data) {

              const camp = state.getByID(data.campaignID);
              if (camp) {
                if(!camp.scheduledReports) camp.scheduledReports = [];
                camp.scheduledReports.push(responseCampaign.data)
              }
            }
            resolve(responseCampaign);
          } else {
            reject(response);
          }
        });
    })
  },


  deleteScheduledReport: (store,state) => (id) => {
    return new Promise((resolve, reject) => {
      if (!id) return reject('No data');
      axios
        .delete(
          API_URL + '/v1/campaign/scheduledReport?id='+id,
          { withCredentials: true }
        )
        .then(response => {
          if (response.status === 200) {
            const responseCampaign = response.data.data;
            if (responseCampaign) {
              const camp = state.getByID(responseCampaign.campaignID);
              if (camp) {
                if(!camp.scheduledReports) camp.scheduledReports = [];
                for(let i = 0; i < camp.scheduledReports.length; i++){
                  if(camp.scheduledReports[i].id === responseCampaign.id) {
                    camp.scheduledReports.splice(i,1);
                    break;
                  }
                }
              }

            }
            resolve(responseCampaign);
          } else {
            reject(response);
          }
        });
    })
  },

  createNewCreative: (store, state) => (campaign_id, data, onFinish) => {
    let campaign = state.getByID(campaign_id);

    if (!data) data = {};
    if (!campaign || campaign.found === false) {
      console.error("Campaign not found", campaign_id)
      state.getCampaign(campaign_id).then((camp) => {
        if (camp) {
          state.createNewCreative(campaign_id, data, onFinish);
        }
      })
      return false;
    }

    if (data.size) {
      const sizeParsed = data.size.split('x');
      data.width = sizeParsed[0];
      data.height = sizeParsed[1];
    }

    let creative = Object.assign({}, defaultCreative, data);
    creative.updatedAt = campaign.updatedAt = currentTimestamp();
    const tempID = 'new_' + makeid(14);
    creative.id = tempID;
    creative.new = true;
    creative.campaignID = campaign_id;
    creative.dataPulledAt = currentTimestamp();

    store.newCreatives[tempID] = null;

    axios
      .post(
        API_URL + '/v1/campaign/creatives',
        creative,
        { withCredentials: true }
      )
      .then(response => {
        if (response.status === 200) {
          let found = false;
          const responseCreative = response.data.data;
          if (!responseCreative.assets) responseCreative.assets = [];
          responseCreative.dataPulledAt = currentTimestamp();
          store.newCreatives[tempID] = responseCreative.id;
          campaign.creatives.forEach((creative, indx) => {
            if (creative.id === tempID) {
              campaign.creatives[indx] = Object.assign(campaign.creatives[indx], responseCreative);
              found = true;
              //store.newCreatives[tempID] = null;
            }
          })
          if (!found) {
            campaign.creatives.push(responseCreative);
          }
          state.update(campaign, false);
          if (onFinish) onFinish(responseCreative);
        }
      });

    return creative;
  },
  updateCreative: (store, state) => (campaign_id, data) => {
    return new Promise((resolve, reject) => {
      let campaign = state.getByID(campaign_id);
      if (!campaign || campaign.found === false) reject('No campaign');

      if (data.id.indexOf('new_') === 0) {
        if (!store.newCreatives[data.id]) {
          console.error('No creative found yet!');
          reject(data);
        }
        data.id = store.newCreatives[data.id];
      }

      data.campaignID = campaign_id;
      axios
        .put(
          API_URL + '/v1/campaign/creatives',
          data,
          { withCredentials: true }
        )
        .then(response => {
          if (response.status === 200) {
            let found = false;
            const responseCreative = response.data.data;

            responseCreative.dataPulledAt = currentTimestamp();
            campaign.creatives.forEach((creative) => {
              if (creative.id === responseCreative.id) {
                creative = Object.assign(creative, responseCreative);
                found = true;
              }
            })
            if (!found) {
              campaign.creatives.push(responseCreative);
            }
            state.update(campaign, false);
            resolve(responseCreative);
          } else {
            reject(response)
          }
        });
    })

  },

  publishCreative: (store, state) => (creative) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + '/v1/campaign/creatives/publish',
          creative,
          { withCredentials: true }
        )
        .then(response => {
          if (response.status === 200) {
            const responseCreative = response.data.data;

            responseCreative.dataPulledAt = currentTimestamp();
            creative = responseCreative;
            let campaign = state.getByID(creative.campaignID);
            if (campaign) {
              campaign.updatedAt = currentTimestamp();
              let found = false;
              campaign.creatives.forEach((creative) => {
                if (creative.id === responseCreative.id) {
                  creative = Object.assign(creative, responseCreative);
                  found = true;
                }
              })
              if (!found) {
                campaign.creatives.push(responseCreative);
              }
              state.update(campaign, false);

            }
            resolve(responseCreative);
          } else {
            reject(response);
          }
        });
    })

  },


  deleteCreative: (store, state) => (creative) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          API_URL + '/v1/campaign/creatives/delete?id=' + creative.id,
          { withCredentials: true }
        )
        .then(response => {
          if (response.status === 200) {
            const responseCreative = response.data.data;

            let campaign = state.getByID(creative.campaignID);
            if (campaign) {
              campaign.updatedAt = currentTimestamp();
              campaign.creatives.forEach((creative, indx) => {
                if (creative.id === responseCreative.id) {
                  campaign.creatives.splice(indx, 1);
                }
              })
              state.update(campaign, false);
            }
            resolve(responseCreative);
          } else {
            reject(response);
          }
        });
    })

  },

  update: (store, state) => (data, updateBackend = true) => {
    if (!data) return false;
    let campaign = null;
    for (let i = store.campaigns.length - 1; i >= 0; --i) {
      if (store.campaigns[i].id == data.id) {
        campaign = Object.assign(store.campaigns[i], data);
      }
    }
    if (updateBackend) {
      axios
        .put(
          API_URL + '/v1/campaign/update',
          campaign,
          { withCredentials: true }
        )
        .then(response => {
          if (response.status === 200) {
            const responseCamp = response.data.data;

            responseCamp.dataPulledAt = currentTimestamp();
            for (let i = store.campaigns.length - 1; i >= 0; --i) {
              if (store.campaigns[i].id == data.id) {
                if (store.campaigns[i].creatives) delete responseCamp.creatives;
                campaign = Object.assign(store.campaigns[i], responseCamp);
              }
            }
            state.saveState();
          } else {
            console.error(response);
          }
        });
    }

    state.saveState();
    return campaign;
  },

  uploadAsset: (store, state) => async (campaignID, creativeID, file, hasTriedBefore = false) => {

    return new Promise((resolve, reject) => {
      if (!campaignID || !creativeID) {
        return reject('Incorrect campaign or creative!');
      }
      let campaign = state.getByID(campaignID);
      if (!campaign || campaign.found === false) {
        console.error("CAMPAIGN NOT FOUND")
        if (hasTriedBefore !== true) {
          state.getCampaign(campaignID).then((camp) => {
            if (camp) {
              campaign = camp;
              state.uploadAsset(campaignID, creativeID, file, true).then(val => resolve(val)).catch(val => reject(val));
            }
          })
        } else {
          return reject('Campaign not found!')
        }
        return false;
      }
      let creative;
      for (let i = campaign.creatives.length - 1; i >= 0; --i) {
        if (campaign.creatives[i].id == creativeID) {
          creative = campaign.creatives[i];
          break;
        }
      }
      const fileName = file.relativeName || file.name;
      const asset = {
        filename: file.fullPath || file.name || fileName,
        name: fileName,
        path: file.fullPath || file.name || fileName,
        new: true,
        creativeID: creativeID,
        url: ''
      };

      let formData = new FormData();

      const meta = analyzeFile(file);

      if (meta) {
        for (let k in meta) {
          formData.append(k, meta[k]);
          asset[k] = meta[k];
        }
      }

      formData.append('creativeID', creativeID);
      formData.append('assets', JSON.stringify(asset));
      formData.append('files[]', file);
      axios
        .post(API_URL + '/v1/campaign/creatives/assets', formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.data.status >= 200 && response.data.status < 400) {
            const asset = response.data.data[0];
            const deleted = response.data.deleted;
            let found = false;
            if (!creative) return;
            if (!creative.assets) creative.assets = [];
            for (let i = creative.assets.length - 1; i >= 0; --i) {
              if (creative.assets[i].filename == asset.filename) {
                creative.assets[i] = Object.assign(creative.assets[i], asset);
                found = true;
                break;
              }
            }
            if (!found) {
              creative.assets.push(asset);
            }
            creative.updatedAt = Date.now();
            if (deleted) {
              for (let m = 0; m < deleted.length; m++) {
                const delObj = deleted[m];
                for (let i = creative.assets.length - 1; i >= 0; --i) {
                  if (creative.assets[i].id == delObj.id) {
                    creative.assets.splice(i, 1);
                    break;
                  }
                }
              }
            }
            //state.saveState();
            return resolve(asset);
          }
          reject(response.data);
        });
      if (creative) {
        if (!creative.assets) creative.assets = [];
        let found = false;
        for (let i = creative.assets.length - 1; i >= 0; --i) {
          if (creative.assets[i].filename == asset.filename) {
            creative.assets[i] = Object.assign(creative.assets[i], asset);
            found = true;
            break;
          }
        }
        if (!found) {
          creative.assets.push(asset);
        }
      }
    })
  },


  deleteAsset: (store, state) => async (asset) => {
    if (!asset.creativeID) {
      console.error("NO creative ID")
      return false;
    }
    let creative = state.getCreativeByID(asset.creativeID);
    if (!creative) {

      console.error("Creative not found")
      return false;
    }
    return new Promise((resolve) => {

      axios
        .delete(
          API_URL + '/v1/campaign/creatives/assets?id=' + asset.id + '&creativeID=' + asset.creativeID,
          { withCredentials: true }
        )
        .then(response => {
          if (response.data.status >= 200 && response.data.status < 400) {
            if (!creative) return;
            if (!creative.assets) creative.assets = [];
            let useCreative = null;
            if (response.data.data) {

              let campaign = state.getByID(creative.campaignID);
              campaign.creatives.forEach((crtive) => {
                if (crtive.id == asset.creativeID) {
                  useCreative = crtive;
                }
              })
              useCreative.updatedAt = Date.now();
              const deletedAsset = response.data.data;
              for (let i = useCreative.assets.length - 1; i >= 0; i--) {
                if (useCreative.assets[i].id == deletedAsset.id || useCreative.assets[i].filename == deletedAsset.filename) {
                  useCreative.assets.splice(i, 1);
                  break;
                }
              }
              return resolve(deletedAsset);
            }
            state.saveState();
            resolve(asset);
          }
        });
    })
  },

  delete: (store, state) => (data) => {
    if (!data) return false;
    let findKey = data.id || data;

    for (let i = store.campaigns.length - 1; i >= 0; --i) {
      if (store.campaigns[i].id == findKey) {
        store.campaigns.splice(i, 1);
      }
    }
    state.saveState();

    return new Promise((resolve, reject) => {
      axios
        .delete(
          API_URL + '/v1/campaign/delete?campaignID=' + findKey,
          { withCredentials: true }
        )
        .then(response => {
          if (response.status === 200) {
            for (let i = store.campaigns.length - 1; i >= 0; --i) {
              if (store.campaigns[i].id == findKey) {
                store.campaigns.splice(i, 1);
                break;
              }
            }
            resolve(response.data);
          } else {
            reject(response);
          }
        });
    })

  },

  saveState: (store) => () => {
    window.localStorage.setItem('campaigns', JSON.stringify(store));
  },

}

const CampaignsServicePublicFunctions = [
  'getByID',
  'getCreativeByID',
  'getAll',
  'getCreatives',
  'createNew',
  'createNewCreative',
  'updateCreative',
  'update',
  'delete',
  'saveState',
  'getCreativeTags',
  'uploadAsset',
  'deleteAsset',
  'publishCreative',
  'deleteCreative',
  'getCampaignReport',
  'getCreativesReport',
  'getCampaignDateReport',
  'getCreativesDateReport',
  'getCreativesDateEvents',
  'createNewReportToken',
  'getSharedDateReport',
  'createNewScheduledReport',
  'deleteScheduledReport',
  'downloadTags'
];

export { CampaignsService, CampaignsServicePublicFunctions };
